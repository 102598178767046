<template>
  <div class="row">
    <div class="col">
      <card-container
        :title="$router.currentRoute.meta.title"
        :lastPage="lastPage"
        :totalElements="totalElements"
        :currentPage="getCurrentPage()"
        :clickHandler="pagination"
      >
        <template v-slot:card-header-search>
          <search
            v-model="lastPage"
            :listHandler="getList"
            style="margin-top: 30px"
          />
        </template>
        <template v-slot:table-rows>
          <div class="row" v-bind:class="{ 'mt-6': !items.length }">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="col-xl-4 col-md-6"
            >
              <div class="card card-stats">
                <!-- Card body -->
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5
                        class="card-title text-uppercase text-muted mb-0 text-custom-dashboard"
                      >
                        {{ item.label }}
                      </h5>
                      <span class="h2 font-weight-bold mb-0">{{
                        item.value
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { CAPTAIN_PERFORMANCE_LIST as CRUD_ACTION_LIST } from "actions/suppliers_portal/captain_performance";
import search from "./partials/search";
const CAPTAINS_CODE = "captain_performance";
const CRUD_CODE = `suppliers_portal.${CAPTAINS_CODE}`;
const CRUD_PERMISSION_CODE = `supplier_${CAPTAINS_CODE}`;

export default {
  name: "SuppliersPortalCaptainIndex",
  components: {
    search,
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CAPTAINS_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      items: [],
      perPage: 20,
      totalElements: 0,
      lastPage: 0,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters([
      "getSearchFilters",
      "getSearchFiltersIfExists",
      "getSearchFiltersSensitized",
    ]),
  },
  methods: {
    getList() {
      this.$store
        .dispatch(CRUD_ACTION_LIST, {
          pageId: this.getCurrentPage() - 1,
          ...this.getSearchFiltersSensitized,
        })
        .then((response) => {
          this.items = response.data.data;
          this.perPage = response.data.perPage;
          this.totalElements = response.data.totalElements;
          this.lastPage = response.data.pageCount;
        });
    },
    pagination(page) {
      if (page !== this.getCurrentPage()) {
        this.$router.push({
          name: `${this.routePrefix}.index`,
          query: {
            page,
            ...this.getSearchFiltersIfExists,
          },
        });
        this.getList();
      }
    },
    getCurrentPage() {
      let currentPage = this.$route.query.page ?? 1;
      return parseInt(currentPage >= 1 ? currentPage : 1);
    },
  },
};
</script>
